import React from 'react'
import './Hamburger.css'
function Hamburger({setNav}) {

  const handleClick = () => {
    setNav(true)
  }
  return (
    <div className='hamburger' onClick={handleClick}>
  
      <div className="burger burger1"></div>
      <div className="burger burger2"></div>
      <div className="burger burger3"></div>
     
    </div>
  )
}

export default Hamburger