import React from 'react'
import ProjectsListItem from './ProjectsListItem'
import git from '../../data/projects'
import './ProjectListItem.css'

export default function ProjectsList() {
  return (
    <div>
      <h2 className='gitrepo'> Git Repos</h2>
      <div className="repos">
      {git.map((item, index) => {
        return <ProjectsListItem key={index} project={item}/>
      })}
      </div>
    </div>
  )
}
