import React from 'react'
import './ProjectListItem.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons'; // Import the required icon

export default function ProjectsListItem({project}) {
  return (
    
    <div className="project">
      <Link to={project && project.url} target="_blank" rel="noopener noreferrer">
      <h2 className="title-pro">{project && project.name}</h2>
      <p className="desc-pro">{project && project.desc}</p>
      <p className="tech-pro"> <FontAwesomeIcon icon={faCircle} style={{ color: ' #4078c0' }}/> &nbsp;{project && project.tech}</p>
      </Link>
    </div>
   
  )
}
