const git = [
  { 
    name: "PropetyPlus",
    desc: "A web app for landlords and tenants to interact with reach other.",
    tech: 'JavaScript   HTML   React   CSS   Node.js   Express',
    url: 'https://github.com/jszady/PropertyPlus'
  },
  { 
    name: "LabberTradder",
    desc: "A web app where users can list/buy/sell vehicles.",
    tech: 'JavaScript   HTML   EJS   CSS   Node.js   Express',
    url: 'https://github.com/jszady/buy-sell'
  },
  { 
    name: "Tweeter",
    desc: "An SPA twitter clone for users to share their thoughts.",
    tech: 'JavaScript   HTML   React   CSS   Node.js   Express',
    url: 'https://github.com/jregan95/tweeter'
  },
  { 
    name: "TinyApp",
    desc: "A web app which users can register/login and shorten urls.",
    tech: 'JavaScript   HTML   EJS   CSS   Node.js   Express',
    url: 'https://github.com/jregan95/tinyapp'
  }
]

export default git;