import React from 'react'
import './FullNav.css'
import { Link } from 'react-router-dom'

function FullNav({setNav}) {
  const handleClick = () => {
    setNav(false)
  }
  return (
    <div className="fullnav">
      <div >
        <button className='close' onClick={handleClick}> X </button>
      </div>
      <div className="options">
        <Link to='/'>
        <div className='list one' onClick={handleClick}> Home </div>
        </Link>
        <Link to="/about">
        <div className='list two' onClick={handleClick}> About </div>
        </Link>
        <Link to="/projects">
        <div className='list three' onClick={handleClick}> Projects </div>
        </Link>
        <Link to='/contact'>
        <div className='list four' onClick={handleClick}> Contact </div>
        </Link>
      </div>
    </div>
  )
}

export default FullNav