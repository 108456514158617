import React from 'react'
import './Home.css'
import image from "./profile2.jpeg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import pdf from './JillReganResume.pdf'



const Home = () => {
  return (
    <div className="ring">
  <i></i>
  <i></i>
  <i></i>
  <div className="info">
   <h1 className="name"> Jill Regan</h1>
   <img className="profile" src={image}></img>
   <div className="icons">
    <Link to='https://github.com/jregan95' target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon className="git-icon" icon={faGithub} size="3x"/>
   </Link>
   <Link to='https://www.linkedin.com/in/jill-regan-b9749021a/?originalSubdomain=ca' target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon className="linked" icon={faLinkedinIn} size="3x"/>
   </Link>
   <a href={pdf}>
   <FontAwesomeIcon className="file" icon={faFile} size="3x" target="_blank" rel="noopener noreferrer"/>
   </a>
   </div>
   
   <h2 className="desc"> Full Stack Developer</h2>
   
  </div>
</div>
  )
}

export default Home