import React from 'react'
import ProjectsList from './Projects/ProjectsList'
import './Projects.css'

export default function Projects() {
  return (
    <div>
      <div className="projectbanner">
        <h1 className="projecttitle">Projects</h1>
      </div>
      <div class="git">
        <ProjectsList/>
      </div>
    </div>
  )
}
