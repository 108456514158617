import React from 'react'
import emailjs from 'emailjs-com';
import './Contact.css'
import { useState } from 'react';

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false)
  
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.send(process.env.REACT_APP_EMAIL_JS, process.env.REACT_APP_TEMPLATE,{
      to_name: name,
      message: message,
      to_email: 'jillian.jnr@hotmail.com',
      email: email
      }, 
      process.env.REACT_APP_USER)
      .then((response) => {
        console.log('Email sent successfully:', response);
      })
      .then(setSent(true))
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  }

  return (
    <div className='contactpage'>
      <div className="contactbanner">
         <h1 className="contacttitle">Contact</h1>
      </div>
      {sent ? ( // Check if 'sent' is true
        <p className="suc-sent">Email sent successfully!</p>
      ) : (
      <form className="contact" onSubmit={sendEmail}>
       <div className="name-field">
        <label> Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        </div>
        <div className="email-field">
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        </div>
        <div className="message-field">
        <label>Message</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows='8'
        ></textarea>
        </div>
        <button className="send-btn" type="submit">Submit</button>
      </form>
        )}
    </div>
  )
}
