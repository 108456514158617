import React from 'react'
import image from './backdrop.jpeg'
import './About.css'

export default function About() {
  return (
    <div>
      <div>
        <img className="banner" src={image}></img>
      </div>
      <div className="moreInfo">
      <p>Hi, I'm Jill!</p>
      <p> I'm a passionate Full Stack Developer with a diverse background.
      With over 7 years of experience in hospitality management, I've honed 
      my skills in communication, problem-solving, and teamwork. Inspired 
      by the dynamic nature of technology, I made a career shift to pursue 
      Software Development. I have hands-on experience with a variety of 
      technologies including JavaScript, Node.js, Express, React, CSS, HTML, 
      and more. 
      </p>
      <p>
      Beyond coding, I'm an avid traveler who has explored over 25 countries. 
      I'm also an outdoor enthusiast, finding inspiration in nature. You'll often 
      find me skiing down the slopes, camping under the stars, hiking picturesque 
      trails, or portaging across tranquil lakes. Whether it's writing code 
      or exploring the great outdoors, I'm always up for an adventure!
      </p>
      </div>
    </div>
  )
}
