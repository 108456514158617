import Nav from './Pages/Nav/Nav';
import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from './Pages/Home';
import About from './Pages/About';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import { useState } from 'react';

function App() {
  const [nav, setNav] = useState(false);

  return (
    <BrowserRouter>
    <Nav nav={nav} setNav={setNav}></Nav>
    <Routes>
     
      <Route path="/" element={<Home/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path='/projects' element={<Projects/>}/>
      <Route path='/contact' element={<Contact/>}/>
      
    </Routes>
  </BrowserRouter>
  );
}

export default App;
