import React from 'react'
import "./Nav.css"
import Hamburger from './Hamburger'
import FullNav from './FullNav'

function Nav({nav, setNav}) {
  return (
    <div className="nav">
  
    {!nav && <Hamburger setNav={setNav}></Hamburger>}
    {nav && <FullNav setNav={setNav}></FullNav>}
    </div>
  )
}

export default Nav